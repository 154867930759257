import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  class: "flex-between"
};
const _hoisted_3 = {
  class: "select-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_Search = _resolveComponent("Search");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_base_table = _resolveComponent("base-table");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_base_option = _resolveComponent("base-option");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 海外仓列表 ")]), _createElementVNode("div", null, [_createVNode(_component_el_input, {
    modelValue: $data.keyword,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.keyword = $event),
    clearable: "",
    placeholder: "海外仓搜索"
  }, {
    suffix: _withCtx(() => [_createVNode(_component_el_icon, {
      class: "icon-click",
      onClick: $options.queryList
    }, {
      default: _withCtx(() => [_createVNode(_component_Search)]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    style: {
      "margin-left": "20px"
    },
    onClick: _cache[1] || (_cache[1] = $event => $options.showOverseaLocationDialog('create'))
  }, {
    default: _withCtx(() => [_createTextVNode(" 新增 ")]),
    _: 1
  })])]), _createVNode(_component_base_table, {
    style: {
      "margin-top": "20px"
    },
    table: $data.table,
    pagination: $data.pagination,
    total: $data.total,
    "icon-visible": false,
    "selection-visible": false,
    onChangePagination: $options.changePagination
  }, {
    default: _withCtx(slotProps => [_createVNode(_component_el_button, {
      link: "",
      type: "primary",
      size: "small",
      style: {
        "width": "40px",
        "margin-left": "20px"
      },
      onClick: $event => $options.showOverseaLocationDialog('update', slotProps.row.id, slotProps.row.name)
    }, {
      default: _withCtx(() => [_createTextVNode(" 修改 ")]),
      _: 2
    }, 1032, ["onClick"])]),
    _: 1
  }, 8, ["table", "pagination", "total", "onChangePagination"]), $data.overseaLocationVisible ? (_openBlock(), _createBlock(_component_base_option, {
    key: 0,
    modelValue: $data.overseaLocationVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.overseaLocationVisible = $event),
    title: $data.overseaLocationType === 'create' ? '新增海外仓' : '修改海外仓',
    width: "25%",
    onCloseDialog: $options.closeOverseaLocation
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      ref: "form",
      model: $data.form,
      rules: $data.formRules
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: $data.overseaLocationType === 'create' ? '海外仓' : '新海外仓名',
        prop: "name"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.form.name,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.name = $event),
          placeholder: "请输入内容",
          clearable: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        style: {
          "float": "right",
          "margin-top": "40px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          onClick: $options.closeOverseaLocation
        }, {
          default: _withCtx(() => [_createTextVNode(" 取消 ")]),
          _: 1
        }, 8, ["onClick"]), _createVNode(_component_el_button, {
          type: "primary",
          onClick: $options.submitOverseaLocation
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["model", "rules"])]),
    _: 1
  }, 8, ["modelValue", "title", "onCloseDialog"])) : _createCommentVNode("", true)]);
}