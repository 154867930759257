import { Search } from '@element-plus/icons-vue';
import BaseOption from '../../../common/base-option-dialog.vue';
export default {
  components: {
    Search,
    BaseOption
  },
  data() {
    return {
      table: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      keyword: '',
      overseaLocationId: 0,
      overseaLocationType: '',
      overseaLocationVisible: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入'
        }, {
          pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
          message: '只允许中英文，数字，下划线，中划线'
        }, {
          min: 1,
          max: 10,
          message: '最多只允许输入10位'
        }]
      }
    };
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.clearAll();
      }
    }
  },
  mounted() {
    this.getOverseaLocation();
  },
  methods: {
    async getOverseaLocation() {
      try {
        await this.$store.dispatch('system/base/getOverseaLocation', {
          params: {
            current_page: this.pagination.current_page,
            page_size: this.pagination.page_size,
            name: this.keyword
          }
        });
        let overseaLocation = this.$store.state.system.base;
        this.table = {
          tableData: overseaLocation.overseaLocation,
          tableFields: [{
            label: 'ID',
            prop: 'id',
            width: '90px',
            show: true
          }, {
            label: '海外仓名称',
            prop: 'name',
            width: '1030px',
            show: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            width: '400px',
            show: true
          }]
        };
        this.total = overseaLocation.overseaLocationLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getOverseaLocation();
    },
    clearAll() {
      this.pagination = {
        current_page: 1,
        page_size: 10
      };
      this.getOverseaLocation();
    },
    changePagination(val) {
      this.pagination = val;
      this.getOverseaLocation();
    },
    showOverseaLocationDialog(type, id, name) {
      this.overseaLocationType = type;
      if (type === 'update') {
        this.overseaLocationId = id;
        this.form.name = name;
      } else {
        this.form.name = '';
      }
      this.overseaLocationVisible = true;
    },
    closeOverseaLocation() {
      this.overseaLocationVisible = false;
      this.$refs.form.resetFields();
    },
    async createOverseaLocation() {
      try {
        await this.$store.dispatch('system/base/createOverseaLocation', {
          name: this.form.name
        });
        this.overseaLocationVisible = false;
        this.$refs.form.resetFields();
        this.pagination.current_page = 1;
        localStorage.removeItem('oversea_location');
        this.getOverseaLocation();
      } catch (err) {
        return;
      }
    },
    async updateOverseaLocation() {
      try {
        await this.$store.dispatch('system/base/updateOverseaLocation', {
          id: this.overseaLocationId,
          name: this.form.name
        });
        this.overseaLocationVisible = false;
        this.$refs.form.resetFields();
        localStorage.removeItem('oversea_location');
        this.getOverseaLocation();
      } catch (err) {
        return;
      }
    },
    async submitOverseaLocation() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.overseaLocationType === 'create') {
            this.createOverseaLocation();
          } else {
            this.updateOverseaLocation();
          }
        }
      });
    }
  }
};